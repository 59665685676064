<template>
  <Detail v-if="show" :id="id" :is-view="true" />
</template>

<script>
import Detail from "./components/Detail";

export default {
  components: { Detail },
  data() {
    return {
      show: false,
      id: ""
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.show = true;
  }
};
</script>
