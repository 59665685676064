<template>
  <div class="header-title u-flex">
    <div class="border mr-8"></div>
    <div>{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.header-title {
  // border-bottom: 1px solid #eee;
  padding: 8px 0;
  display: flex;
  font-weight: bold;
  margin-bottom: 16px;
  .border {
    width: 4px;
    height: 16px;
    background: #598DF3;
    border-radius: 2px;
  }
}
</style>
